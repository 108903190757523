<template>
    <v-row justify="center" class="py-5" align-content="center" style="height: 100%;">
        <v-col cols="12" sm="6">
            <template v-if="!isReady">
                <v-card class="signup-card">
                    <v-toolbar dense flat :color="primaryColor" dark>
                        <v-toolbar-title>Xentri profile</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                        <p class="mb-0">
                            <v-progress-circular :color="primaryColor" indeterminate width="2" size="16" class="mr-2"></v-progress-circular>
                            Checking profile...
                        </p>
                    </v-card-text>
                </v-card>
                <!-- <p class="mt-6 grey--text text--darken-2 text-center">Already have an account? <router-link :to="{ name: 'login', query: { } }">Sign in</router-link></p> -->
            </template>
            <template v-if="showRedirectCard">
                <v-card class="signup-card">
                    <v-toolbar dense flat :color="primaryColor" dark>
                        <v-toolbar-title>Xentri profile</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                        <p class="mb-0">
                            <v-progress-circular :color="primaryColor" indeterminate width="2" size="16" class="mr-2"></v-progress-circular>
                            Redirecting to <a :href="redirectURL">{{ redirectLabel }}</a>...
                        </p>
                    </v-card-text>
                </v-card>
                <!-- <p class="mt-6 grey--text text--darken-2 text-center">Already have an account? <router-link :to="{ name: 'login', query: { } }">Sign in</router-link></p> -->
            </template>
            <template v-if="isReady && !showRedirectCard && !isError">
                <v-card class="signup-card">
                    <v-toolbar dense flat :color="primaryColor" dark>
                        <v-toolbar-title>Xentri profile</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="checkXentriProfile">
                            <font-awesome-icon :icon="['far', 'sync-alt']" fixed-width/>
                        </v-btn>
                    </v-toolbar>
                    <v-form @submit.prevent="redirectToXentriSetup" onSubmit="return false;" @keyup.enter.native.prevent="redirectToXentriSetup" class="mx-4 mt-4 pb-6">
                        <p class="help"><b>About Xentri</b></p>
                        <p class="help" v-if="!isXentriSetupComplete">
                            We use Xentri to protect your account.
                            When you tap on "Continue" will will redirect you to the Xentri
                            portal to set your account password.
                        </p>
                        <p class="help" v-if="isXentriSetupComplete">
                            We use Xentri to protect your account.
                            When you tap on "Continue" will will redirect you to the Xentri
                            portal where you can change your account password.
                        </p>
                        <p class="help">
                            <b>Store your password in a safe place!</b>
                        </p>
                        <p class="help">Your Cryptium VPN account is anonymous so we will
                            be able to help you recover access to the
                            account if you lose your password.</p>
                        <!-- TODO: show xentri portal in an iframe here , OR use their API to set a one-time password and just display it here for user to copy ... and tell them they can change it later -->
                        <v-row no-gutters justify="center" class="mt-6">
                            <v-btn @click="redirectToXentri" elevation="4" color="#3F51B5" class="white--text mx-2">Continue</v-btn>
                        </v-row>
                    </v-form>
                </v-card>
                <!-- <v-expansion-panels accordion multiple>
                    <v-expansion-panel style="border-radius: 0px;">
                        <v-expansion-panel-header style="font-size: 18px!important;">Store your password in a safe place!</v-expansion-panel-header>
                        <v-divider></v-divider>
                        <v-expansion-panel-content>
                            <p>Your Cryptium VPN account is anonymous so we will
                            not have any way to help you recover access to the
                            account if you lose your password.</p><p>
                            Xentri may have self-service account recovery options
                            available. If you provide any contact information to
                            Xentri, that information will <b>NOT</b> be available
                            to Cryptium VPN.</p>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels> -->
            </template>
        </v-col>
    </v-row>
</template>

<style>
.signup-card p.prompt {
    font-size: 1.3em;
    color: #333333;
}
.signup-card p.help {
    font-size: 1.1em;
    color: #555555;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';
import { isValidProductLookupKey } from '@/sdk/input';

export default {
    components: {
    },
    data: () => ({
        isReady: false, // true after we check xentri profile
        showRedirectCard: false,
        isRedirecting: false,
        redirectURL: null,
        redirectLabel: null,
        isXentriSetupComplete: null, // true if user has set up their authentication settings in xentri, false if user still hasn't done that
        product: null,
        submitTimestamp: null,
        serverError: false,
        serverErrorTimeout: null,
        requestError: false,
        requestErrorTimeout: null,
        inputError: null,
        inputErrorTimeout: null,
        forbiddenError: null,
        forbiddenErrorTimeout: null,
    }),
    computed: {
        ...mapState({
            isAuthenticated: (state) => state.session.isAuthenticated,
            user: (state) => state.user,
            account: (state) => state.account,
            focus: (state) => state.focus,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
        }),
        isError() {
            return this.serverError || this.requestError || this.inputError || this.forbiddenError;
        },
    },
    methods: {
        resetErrors() {
            this.serverError = false;
            if (this.serverErrorTimeout) {
                clearTimeout(this.serverErrorTimeout);
                this.serverErrorTimeout = null;
            }
            this.requestError = false;
            if (this.requestErrorTimeout) {
                clearTimeout(this.requestErrorTimeout);
                this.requestErrorTimeout = null;
            }
            this.inputError = null;
            if (this.inputErrorTimeout) {
                clearTimeout(this.inputErrorTimeout);
                this.inputErrorTimeout = null;
            }
            this.forbiddenError = false;
            if (this.forbiddenErrorTimeout) {
                clearTimeout(this.forbiddenErrorTimeout);
                this.forbiddenErrorTimeout = null;
            }
        },
        async checkXentriProfile() {
            try {
                this.resetErrors();
                this.isReady = false;
                this.$store.commit('loading', { checkXentriProfile: true });
                const response = await this.$client.user(this.user.id).user.check({ xentri: true });
                console.log(`checkXentriProfile response ${JSON.stringify(response)}`);
                if (response?.type === 'status' && response.status?.xentri?.id) {
                    console.log('xentri registration successful');
                    if (response.status.xentri.ready) {
                        this.isXentriSetupComplete = true;
                    } else {
                        this.isXentriSetupComplete = false;
                    }
                } else {
                    this.serverError = 'Please try again later';
                }
            } catch (err) {
                console.error('failed to sign up', err);
                if (err.response?.status) {
                    console.error(`response status: ${err.response.status}`);
                    // TODO: 300 error codes? server shouldn't be redirecting us...
                    if (err.response.status === 403) {
                        this.resetErrors();
                        this.interactionId = null; // or else user will immediately get same forbidden error again; to start over we need to clear the interaction id
                        this.forbiddenError = true;
                        this.forbiddenErrorTimeout = setTimeout(() => { this.forbiddenError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 400 && err.response.status < 500) {
                        this.requestError = true;
                        this.requestErrorTimeout = setTimeout(() => { this.requestError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 500) {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    } else {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                }
            } finally {
                this.$store.commit('loading', { checkXentriProfile: false });
                this.isReady = true;
            }
        },
        async redirectToXentri() {
            // check if user has a xentri id already
            if (this.isXentriSetupComplete) {
                await this.redirectToXentriEdit();
            } else {
                await this.redirectToXentriSetup();
            }
        },
        async redirectToXentriSetup() {
            const request = {
                intent: 'signup',
                // TODO: when we can show xentri in an iframe we won't need a return path
                signup_params: {
                    product: this.product,
                },
            };
            await this.redirectToXentriWithRequest(request);
        },
        async redirectToXentriEdit() {
            const request = {
                intent: 'prefs',
            };
            await this.redirectToXentriWithRequest(request);
        },
        async redirectToXentriWithRequest(request) {
            try {
                this.resetErrors();
                this.$store.commit('loading', { redirectToXentri: true });
                console.log(`request ${JSON.stringify(request)}`);
                const response = await this.$client.user(this.user.id).user.redirectToXentriSettings(request);
                console.log(`redirectToXentriSettings response ${JSON.stringify(response)}`);
                if (response?.type === 'redirect' && response.redirect) {
                    this.redirect(response.redirect, 'Xentri');
                } else {
                    this.serverError = 'Please try again later';
                }
            } catch (err) {
                console.error('failed to sign up', err);
                if (err.response?.status) {
                    console.error(`response status: ${err.response.status}`);
                    // TODO: 300 error codes? server shouldn't be redirecting us...
                    if (err.response.status === 403) {
                        this.resetErrors();
                        this.interactionId = null; // or else user will immediately get same forbidden error again; to start over we need to clear the interaction id
                        this.forbiddenError = true;
                        this.forbiddenErrorTimeout = setTimeout(() => { this.forbiddenError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 400 && err.response.status < 500) {
                        this.requestError = true;
                        this.requestErrorTimeout = setTimeout(() => { this.requestError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 500) {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    } else {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                }
            } finally {
                this.$store.commit('loading', { redirectToXentri: false });
                // this.isViewReady = true;
            }
        },
        redirect(redirectURL, redirectLabel) {
            if (this.isRedirecting) {
                // redirect already in progress
                return;
            }
            // redirect here
            this.isRedirecting = true;
            this.redirectURL = redirectURL;
            this.redirectLabel = redirectLabel;
            // show a link after 2 seconds in case auto-redirect fails or takes a long time
            setTimeout(() => {
                this.showRedirectCard = true;
                this.isRedirecting = false;
            }, 2000);

            if (typeof window.location.push === 'function') {
                window.location.push(redirectURL);
            } else {
                window.location.href = redirectURL;
            }
        },
    },
    mounted() {
        if (this.$route.query.product && isValidProductLookupKey(this.$route.query.product)) {
            this.product = this.$route.query.product;
        }

        this.checkXentriProfile();
    },
};
</script>
